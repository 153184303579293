/* fonts injections from here */
@font-face {
  font-family: 'open_sansregular';
  font-style: normal;
  font-weight: 300 800;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('/fonts/OpenSans/OpenSans-VariableRegular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'open_sansregular';
  font-style: italic;
  font-weight: 300 800;
  font-display: swap;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('/fonts/OpenSans/OpenSans-VariableItalic.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Neue Plak';
  font-style: bold;
  font-display: swap;
  src: url('/fonts/Neue/NeuePlakCondensed.ttf') format('truetype');
}

/* 
@font-face {
  font-family: 'Bliss Pro';
  src: url('/fonts/Blisspro/BlissPro-Regular.ttf');
  format: ('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bliss Pro Extra Bold';
  src: url('/fonts/Blisspro/BlissPro-ExtraBold.ttf');
  format: ('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bliss Pro Heavy';
  src: url('/fonts/Blisspro/BlissPro-Heavy.ttf');
  format: ('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bliss Pro Light Italic';
  src: url('/fonts/Blisspro/BlissPro-LightItalic.ttf');
  format: ('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bliss Pro Light';
  src: url('/fonts/Blisspro/BlissPro-Light.ttf');
  format: ('truetype');
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Bliss Pro Bold';
  src: url('/fonts/Blisspro/BlissPro-Bold.ttf');
  format: ('truetype');
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Bliss Pro Medium';
  src: url('/fonts/Blisspro/BlissPro-Medium.ttf');
  format: ('truetype');
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Bliss Pro Medium Italic';
  src: url('/fonts/Blisspro/BlissPro-MediumItalic.ttf');
  format: ('truetype');
  font-style: bold;
  font-display: swap;
}
*/
:root {
  --open-sans: open_sansregular, Arial, sans-serif;
  --header-height: 5.625rem;
  --breadcrumb-height: 1.875rem;
}
html,
body {
  font-family: var(--open-sans);
  scroll-padding-top: 6rem
  /* overflow-x: hidden; This causes issues with sticky elements. */
}

.customMenuItem {
  cursor: pointer;
}

#bidx1-launcher-iframe {
  z-index: 100 !important;
}

.MuiTypography-root,
.MuiButton-root.MuiButtonBase-root,
.MuiInputBase-root,
.MuiInputLabel-root {
  font-family: var(--open-sans) !important;
}

.MuiTypography-h1 {
  font-weight: 700;
}

.MuiTypography-h2 {
  font-weight: 600;
}

.MuiTypography-light-italic {
  font-weight: 300;
  font-style: italic;
}

.MuiTypography-medium-italic {
  font-weight: 500;
  font-style: italic;
}

.MuiTypography-regular {
  font-weight: 400;
}

.MuiTypography-medium {
  font-weight: 500;
}

.MuiTypography-bold {
  font-weight: 700;
}

.MuiTypography-extra-bold,
.MuiTypography-heavy {
  font-weight: 800;
}